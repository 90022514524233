<template>
    <div class="ui-search-dropdown">
        <label :id="`label-${_uid}`" :for="`input-${_uid}`" class="combobox-label">
            <slot name="label" />
        </label>
        <span v-if="validation" class="required ml-1">({{ translate('required') }})</span>

        <input
            :id="validationId"
            type="text"
            :value="selectedItem"
            class="hidden-input"
            :required="!!validation"
            :validation="validation"
            tabindex="-1"
            aria-hidden="true"
            :autocorrect="autocorrect"
        />

        <!-- class .ui-combobox is used as selector in onBlur -->
        <div ref="uiComboBox" class="ui-combobox">
            <button
                ref="toggleButton"
                type="button"
                :tabindex="tabindex"
                class="ui-dropdown-search-button d-flex justify-content-between align-items-center w-100"
                :class="{
                    active: show,
                    'text-placeholder': selectedItem === clearOption,
                    error: validation && hasError,
                    valid: validation && isValid,
                }"
                :aria-pressed="show"
                @click.stop.prevent="toggleCombobox"
                @blur="emitBlur"
            >
                <slot name="button-content" :selected-item="selectedItem" />
                <UIIcon :name="['fal', 'chevron-down']" class="dropdown-icon dropdown-chevron d-none d-md-block" />
                <UIIcon class="dropdown-icon d-md-none" :name="['far', 'search']" />
            </button>

            <div
                v-show="show"
                ref="comboboxWrapper"
                class="combobox-wrapper w-100"
                :class="[{ open: show }, dropDirectionClass]"
                tabindex="-1"
            >
                <div
                    role="combobox"
                    :aria-expanded="show"
                    :aria-owns="`listbox-${_uid}`"
                    :aria-haspopup="`listbox-${_uid}`"
                    class="search-input-container"
                >
                    <UIRipple
                        color="black"
                        :opacity="0.15"
                        :speed="3"
                        tabindex="0"
                        @keydown.enter.native.stop.prevent="closeCombobox"
                        @click.native="closeCombobox"
                    >
                        <UIIcon :name="['fal', 'arrow-left']" />
                    </UIRipple>

                    <UIFormInput
                        :id="`input-${_uid}`"
                        ref="listboxInput"
                        v-model="searchInput"
                        class="pl-5 pl-md-3"
                        type="search"
                        autocomplete="none"
                        data-onblur-hover="false"
                        data-prevent-dropdown-blur="true"
                        :placeholder="placeholder"
                        :aria-controls="`listbox-${_uid}`"
                        :aria-labelledby="`label-${_uid}`"
                        :autocorrect="autocorrect"
                        @keydown.esc="closeCombobox"
                        @keydown.enter.stop.prevent="inputEnterKeyHandler"
                        @keydown.down.prevent="clearOption ? focusClearOption() : focusListboxOption(0)"
                        @input="filterListbox"
                        @blur="onBlur"
                    />

                    <div class="d-md-none results-counter">
                        <template v-if="searchInput.length > 0">
                            {{ translate('search.resultsFor', { value: searchResultsLength }) }} "{{ searchInput }}"
                        </template>
                        <template v-else>
                            {{ translate('search.results', { value: searchResultsLength }) }}
                        </template>
                    </div>
                    <div class="d-md-none w-100 popup-label">
                        <template v-if="!!$scopedSlots['popup-label']">
                            <slot name="popup-label" />
                        </template>
                        <template v-if="!$scopedSlots['popup-label']"> - </template>
                    </div>

                    <div
                        class="input-addon"
                        :class="{
                            'pointer-events-none':
                                (!fuseLoading && searchInput.length === 0) || (fuseLoading && searchInput.length === 0),
                        }"
                    >
                        <UILoadingIndicator v-if="fuseLoading && searchInput.length !== 0" />

                        <UIIcon
                            v-if="
                                (!fuseLoading && searchInput.length === 0) || (fuseLoading && searchInput.length === 0)
                            "
                            class="icon"
                            :name="['far', 'search']"
                            aria-hidden="true"
                        />

                        <div
                            v-if="!fuseLoading && searchInput.length !== 0"
                            class="remove-search"
                            role="button"
                            aria-label="Clear input"
                            tabindex="0"
                            @click="clearButtonHandler"
                            @keydown.enter="clearButtonHandler"
                        >
                            <UIIcon class="icon" :name="['fal', 'times']" />
                        </div>
                    </div>
                </div>

                <div
                    v-if="show"
                    :id="`listbox-${_uid}`"
                    ref="listbox"
                    class="listbox"
                    role="listbox"
                    aria-live="polite"
                    :aria-labelledby="`label-${_uid}`"
                    :aria-activedescendant="`${_uid}-option-${getItemIndex(selectedItem)}`"
                >
                    <div
                        v-if="clearOption"
                        :id="`${_uid}-option-clear`"
                        ref="clearOption"
                        role="option"
                        class="option"
                        tabindex="0"
                        data-onblur-hover="false"
                        value=""
                        @click.stop="clickHandler(undefined, $event)"
                        @keydown.stop="keyHandler(undefined, $event)"
                        @blur="onBlur"
                    >
                        {{ clearOption }}
                    </div>

                    <div
                        v-for="(item, index) in filteredList"
                        :id="`${_uid}-option-${index}`"
                        :key="item.value"
                        ref="listboxOptions"
                        role="option"
                        :aria-disabled="disabledItems.includes(item)"
                        class="option"
                        tabindex="0"
                        data-onblur-hover="false"
                        :data-option-id="`${_uid}-option-${index}`"
                        :class="{ selected: item === selectedItem, disabled: disabledItems.includes(item) }"
                        :aria-selected="item === selectedItem"
                        @click.stop="clickHandler(item, $event)"
                        @keydown.stop="keyHandler(item, $event)"
                        @blur="onBlur"
                    >
                        <div class="overflow-ellipsis">
                            <slot name="option-content" :item="item" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UIOverlay
            v-if="show && modalIsActive"
            id="overlay"
            class="searchdropdown-overlay d-md-none"
            @click.stop.prevent
        />
    </div>
</template>

<script>
import { UIIcon, UIRipple, UIFormInput, UILoadingIndicator, UIOverlay } from '@dundle/ui/components';
import { mapActions, mapGetters } from 'vuex';
import { debounceAsync } from '@dundle/utils/function';
import TranslationMixin from '~/mixins/TranslationMixin';
import ValidationMixin from '~/mixins/ValidationMixin';

/*
 *   Emitting a 'search' event to provide list items from the API by the parent
 *   By default it uses fuseJS for searching, except when the property use-fuse is set to false
 */

export default {
    components: {
        UIIcon,
        UIRipple,
        UIFormInput,
        UILoadingIndicator,
        UIOverlay,
    },
    mixins: [TranslationMixin, ValidationMixin],
    props: {
        placeholder: {
            type: String,
            required: true,
        },
        // the selected item
        defaultItem: {
            type: Object,
            required: false,
        },
        // the dropdown list items
        items: {
            type: Array,
            required: true,
        },
        // use this to provide the search values for fuseJS
        searchIndex: {
            type: Array,
            required: false,
        },
        // use this if you want an option that clears the input
        clearOption: {
            type: String,
            required: false,
        },
        tabindex: {
            type: Number,
            required: false,
        },
        validation: {
            type: String,
            required: false,
        },
        // use validationID to show error messages in parent, see AddressAutoComplete component for example
        validationId: {
            type: String,
            required: false,
        },
        // set to false if you don't want to use fuse to search the items
        useFuse: {
            type: Boolean,
            required: false,
            default: true,
        },
        // added for the checkout search to be able to disable selection of certain items
        disabledItems: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        availableModal: {
            type: String,
            required: false,
        },
        autocorrect: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            show: false,
            searchInput: '',
            item: undefined,
            search: undefined,
            list: this.items, // used for fuse
            fuseLoading: false,
            revertDropDirection: false,
            toggleButtonRef: undefined, // defined in data() so the ref list does not have to be searched every time
            comboboxWrapperRef: undefined,
            listboxRef: undefined,
            listboxInputRef: undefined,
            listboxOptions: undefined,
            clearOptionRef: undefined,
            uiComboBoxRef: undefined,
        };
    },

    computed: {
        ...mapGetters({
            overlayIsActive: 'ui/overlayIsActive',
            activeModal: 'ui/activeModal',
        }),
        selectedItem: {
            get() {
                if (this.item === undefined) return this.defaultItem;
                return this.item;
            },
            set(item) {
                this.item = item;
            },
        },
        filteredList() {
            // if 'refIndex' is set, these are search results from fuse.js
            if (this.useFuse && this.list[0]) {
                // strip refIndex to be able to show details
                if (this.list[0].refIndex !== undefined) return Array.from(this.list, item => item.item);
                return this.list;
            }
            // return the items set by the parent
            return this.items;
        },
        searchResultsLength() {
            // when fuse is used, we show all the results when there is no match
            if (this.useFuse && this.searchInput.length > 0 && this.filteredList.length === this.items.length) {
                return 0;
            }
            return this.filteredList.length;
        },
        dropDirectionClass() {
            if (this.revertDropDirection === true) return 'dropup';
            return 'dropdown';
        },
        modalIsActive() {
            if (this.activeModal && this.availableModal) return this.availableModal === this.activeModal.name;
            return false;
        },
    },

    // When pressing the back button on mobile, close the dropdown screen
    // Clashes with de modal back functionality after route change
    // watch: {
    //     $route(to, from) {
    //       if (this.$device.isMobile()) {
    //         if (from.hash.includes('#search') && from.hash.includes(this._uid)) {
    //           this.closeCombobox();
    //         }

    //         if (to.hash.includes('#search') && to.hash.includes(this._uid)) {
    //           if (!this.search) {
    //             this.fuseLoading = true;
    //             this.initFuseJs();
    //           }
    //           this.openCombobox();
    //         }
    //       }
    //     }
    // },

    watch: {
        filteredList() {
            if (!this.$device.isMobile()) {
                // the list items are debounced, so there needs to be a watcher on the items to set the correct height of the dropdown
                this.setListboxHeight();
            }
        },
    },

    methods: {
        ...mapActions({
            lockPageScroll: 'ui/lockScroll',
            unlockPageScroll: 'ui/unlockScroll',
        }),
        async initFuseJs() {
            const Fuse = (await import('fuse.js')).default;
            const options = {
                shouldSort: true,
                threshold: 0.3,
                keys: this.searchIndex,
            };

            this.search = new Fuse(this.items, options);
            this.fuseLoading = false;
            this.filterListbox();
            this.setSelectedItemFirst();
        },
        checkUserFocus(e) {
            if (this.$refs.listboxInput && document.activeElement === this.$refs.listboxInput.$el) {
                this.listboxInputRef.$el.blur();
            }
        },
        initSearchEmitter() {
            // set the search function be an emitter of an event named 'search', so the parent can intercept and provide search results
            this.search = {};
            this.search.search = function search(input) {
                this.$emit('search', input);
            }.bind(this);
        },
        preventWindowOverflow() {
            // reset to normal dropdown
            this.revertDropDirection = false;

            if (!this.comboboxWrapperRef) this.comboboxWrapperRef = this.$refs.comboboxWrapper;
            this.comboboxWrapperRef.style.top = '';

            this.$nextTick(() => {
                // check if dropdown falls outside window
                if (!this.isInViewport(this.$refs.listbox)) {
                    this.revertDropDirection = true;
                }
            });
        },
        setListboxHeight() {
            this.$nextTick(() => {
                if (!this.listboxRef) this.listboxRef = this.$refs.listbox;
                if (this.listboxRef) {
                    const noOfItems = this.filteredList.length;
                    // convert REMS to pixels (1 item is 3 rem)
                    const optionHeight = 3 * parseFloat(getComputedStyle(document.documentElement).fontSize);
                    const computedHeight = window.getComputedStyle(this.listboxRef).getPropertyValue('height');
                    const maxHeight = 5.5 * optionHeight + 5.5 * 2;
                    // max visible options = 5,5 plus borders
                    if (noOfItems <= 5) {
                        // set default to 1 option
                        let optionsHeight = optionHeight + 2;
                        if (noOfItems !== 0) {
                            if (!this.clearOption) optionsHeight = noOfItems * optionHeight + 2;
                            else optionsHeight = (noOfItems + 1) * optionHeight + 2;
                        }
                        this.listboxRef.style.height = `${optionsHeight}px`;
                    } else if (
                        this.listboxRef.style.height !== '' &&
                        this.listboxRef.style.height !== `${computedHeight}px`
                    ) {
                        this.listboxRef.style.height = `${maxHeight}px`;
                    }
                    // go to dropup
                    if (this.revertDropDirection === true) {
                        if (!this.comboboxWrapperRef) this.comboboxWrapperRef = this.$refs.comboboxWrapper;
                        const comboboxHeight = window
                            .getComputedStyle(this.comboboxWrapperRef)
                            .getPropertyValue('height');
                        this.comboboxWrapperRef.style.top = `-${comboboxHeight}`;
                    }
                }
            });
        },
        toggleCombobox() {
            // this.search is set to the emitter or fuseJS at first opening combobox
            if (!this.search) {
                // for backwards compatibility of searchdropdown not having use-fuse prop
                if (this.useFuse === false) {
                    this.initSearchEmitter();
                } else {
                    // use fuseJS if no endpoint is used
                    this.fuseLoading = true;
                    this.initFuseJs();
                }
            }

            this.show = !this.show;
            if (this.show) {
                this.openCombobox();
            } else {
                this.closeCombobox();
            }
        },
        openCombobox() {
            if (this.searchInput.length <= 0) {
                this.list = this.items;
            }

            this.show = true;

            this.setSelectedItemFirst();

            if (!this.$device.isMobile()) {
                this.preventWindowOverflow();
                this.setListboxHeight();
            }

            this.$nextTick(() => {
                this.focusInput();

                // if mobile check the scroll to hide the keyboard, to prevent the scroll bug on iPhone
                if (this.$device.isMobile()) {
                    this.$refs.listbox.addEventListener('touchstart', this.checkUserFocus);
                    this.lockPageScroll();
                }
            });

            this.$emit('open');
        },
        closeCombobox() {
            // remove the event listening scrolling
            if (this.$device.isMobile()) {
                this.$refs.listbox.removeEventListener('touchstart', this.checkUserFocus);
                this.unlockPageScroll();
            }

            this.show = false;
            this.focusButton();

            this.$emit('close');
        },
        selectItem(item) {
            if (!this.disabledItems.includes(item)) {
                this.selectedItem = this.filteredList.find(listItem => listItem === item);
                this.closeCombobox();
                this.clearInput();
                this.focusButton();
                this.$emit('selected', this.selectedItem);
                this.$emit('input', this.selectedItem);
            }
        },
        filterListbox() {
            if (!this.fuseLoading && this.search) {
                // use fuse search filter
                if (this.useFuse) {
                    const searchResults = this.search.search(this.searchInput);

                    // emit when there are no results
                    if (searchResults.length === 0 && this.searchInput.length > 0) this.emitNoResults(this.searchInput);

                    if (searchResults.length > 0 || (searchResults.length === 0 && this.searchInput.length > 0))
                        this.list = searchResults;
                    else this.list = this.items;
                } else {
                    // use the search function to emit
                    debounceAsync(this.search.search(this.searchInput), 500);
                }
            }
        },
        focusButton() {
            if (!this.toggleButtonRef) this.toggleButtonRef = this.$refs.toggleButton;
            this.toggleButtonRef?.focus();
        },
        focusInput() {
            // wait for next tick, to make sure the dropdown is open
            this.$nextTick(() => {
                if (!this.listboxInputRef) this.listboxInputRef = this.$refs.listboxInput;
                if (this.listboxInputRef) this.listboxInputRef.$el.focus();
            });
        },
        focusListboxOption(index) {
            // set to ref with same id
            // get ref with same id, $refs order is different from list order after search

            if (!this.listboxOptionsRef) this.listboxOptionsRef = this.$refs.listboxOptions;
            const nextItemRef = this.listboxOptionsRef.filter(
                ref => ref.getAttribute('data-option-id') === `${this._uid}-option-${index}`
            );
            if (nextItemRef[0]) nextItemRef[0].focus();
        },
        focusClearOption() {
            if (!this.clearOptionRef) this.clearOptionRef = this.$refs.clearOption;
            this.clearOptionRef.focus();
        },
        getItemIndex(item) {
            return this.filteredList.indexOf(item);
        },
        setSelectedItemFirst() {
            // when entering the dropdown, set selected option first to it is always focused (a11y rule)
            if (this.selectedItem && this.list[0] !== this.selectedItem) {
                // get selected item from
                const listItem = this.list.find(item => item.value === this.selectedItem.value);
                // prevent unshifting an undefined element
                if (listItem) {
                    // remove from the list
                    const index = this.list.indexOf(listItem);
                    if (index > -1) {
                        this.list.splice(index, 1);
                    }
                    // add it to the beginning of the list
                    this.list.unshift(listItem);
                }
            }
        },
        onBlur(e) {
            if (this.modalIsActive) return;
            if (!this.uiComboBoxRef) this.uiComboBoxRef = this.$refs.uiComboBox;

            // trigger validation
            this.validateOnChange({ id: this.validationId || this._uid, value: this.selectedItem || '' });

            if (e.relatedTarget === null && !this.$device.isMobile()) {
                this.closeCombobox();
                this.clearInput();
                this.focusButton();
            } else if (e.relatedTarget && e.relatedTarget.closest('.ui-combobox') !== this.uiComboBoxRef) {
                // close this dropdown element when another element is focused, set focus to other element
                this.closeCombobox();
                this.clearInput();
                e.relatedTarget.focus();
            }
        },
        emitNoResults(searchValue) {
            this.$emit('no-results', searchValue);
        },
        emitBlur(e) {
            if (!e.relatedTarget || (e.relatedTarget && !e.relatedTarget.dataset.preventDropdownBlur)) {
                this.$emit('blur', e);
            }
        },
        clearInput(e) {
            this.searchInput = '';
            this.filterListbox();
            this.setSelectedItemFirst();
        },
        clearButtonHandler() {
            this.clearInput();
            this.focusInput();
        },
        clickHandler(item, e) {
            this.selectItem(item);
        },
        inputEnterKeyHandler() {
            // when enter is pressed in input field
            // check if there is only one item in list and select that one
            if (this.list.length === 1) {
                this.selectItem(this.list[0]);
            }
        },
        keyHandler(item, e) {
            // prevent scrolling that triggers the blur input
            e.stopPropagation();
            e.preventDefault();
            const currentItemIndex = this.getItemIndex(item);
            switch (e.keyCode) {
                case 9:
                    // TAB
                    // filtered list is not the same as dom list, so tab does not work as expected
                    if (e.shiftKey) {
                        // prevent traplock
                        if (currentItemIndex !== 0) {
                            // focus on previous item
                            this.focusListboxOption(currentItemIndex - 1);
                        }
                        if (currentItemIndex === 0) {
                            // focus the input
                            this.focusInput();
                        }
                        // if option to clear dropdown is current item
                        if (this.clearOption && currentItemIndex === -1) {
                            this.focusInput();
                        }
                    } else {
                        // focus on next item
                        if (currentItemIndex === this.list.length - 1) {
                            // when focus is on last item, focus on first item
                            if (!this.clearOption) this.focusListboxOption(0);
                            else {
                                this.focusClearOption();
                            }
                        } else {
                            this.focusListboxOption(currentItemIndex + 1);
                        }
                    }
                    break;
                case 13:
                    // Enter
                    this.selectItem(item);
                    break;
                case 32:
                    // Space
                    this.selectItem(item);
                    break;
                case 27:
                    // Escape
                    this.closeCombobox();
                    break;
                case 38:
                    // Arrow up
                    if (currentItemIndex === 0) {
                        // when focus is on first item, focus on last item or on the item to clear dropdown
                        if (!this.clearOption) this.focusListboxOption(this.list.length - 1);
                        else this.focusClearOption();
                        break;
                    }
                    // if option to clear dropdown is current item, focus on last item
                    if (this.clearOption && currentItemIndex === -1) {
                        this.focusListboxOption(this.list.length - 1);
                    } else {
                        // set focus to previous item
                        this.focusListboxOption(currentItemIndex - 1);
                    }
                    break;
                case 40:
                    // Arrow down
                    if (currentItemIndex === this.list.length - 1) {
                        // when focus is on last item, focus on first item
                        if (!this.clearOption) this.focusListboxOption(0);
                        else this.focusClearOption();
                        break;
                    }
                    // focus on next item
                    this.focusListboxOption(currentItemIndex + 1);
                    break;
                case 36:
                    // Home
                    // focus on first item
                    if (!this.clearOption) this.focusListboxOption(0);
                    else this.focusClearOption();
                    break;
                case 35:
                    // End
                    // focus on last item
                    this.focusListboxOption(this.list.length - 1);
                    break;
                default:
                    // focus on input and begin filtering
                    if (e.keyCode >= 65 && e.keyCode <= 90) this.focusInput();
                    break;
            }
        },
        isInViewport(elem) {
            const rect = elem.getBoundingClientRect();
            // elem is not visible when width is 0
            if (rect.width > 0) {
                // top = menu
                return (
                    rect.top >= 100 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
$button-height: 3em;

// listbox height is checked, max-height: 5,5 options
$listbox-height: 17.5em;

$focus-color: $color-primary;
$selected-color: $color-grey-2;
$input-height-mobile: 4rem;
$input-height-desktop: 3rem;
$option-padding: 0.5rem 1rem;

// extra information about search results
$results-counter-height: 4rem;
$popup-label-height: 1.5rem;

.ui-search-dropdown {
    position: relative;
    height: 100%;
}

.ui-combobox {
    position: relative;
    height: 100%;
}

.combobox-label {
    // needed to override the inline-block that causes blank space
    display: inline;
}

.searchdropdown-overlay {
    z-index: 11;
    position: fixed;
}

.hidden-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// all about the button
.ui-dropdown-search-button {
    position: relative;
    padding: 0.5rem 1rem;
    border: 1px solid $color-grey-3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff;
    line-height: 1.75;
    border-radius: 0.25rem;
    color: $color-text;

    height: 100%;
    // needed for offset of the dropdown
    @media screen and (min-width: 1024px) {
        height: $button-height;
    }

    .dropdown-chevron {
        width: 18px;
        height: 18px;
        margin-left: auto;
        color: $color-grey-6;
        transition: transform 0.2s;
        transform-style: preserve-3d;
    }

    &.active {
        .dropdown-chevron {
            color: $color-grey-6;
            transform: rotateX(-180deg);
        }
    }

    outline: none;
    &:focus {
        border: 1px solid $focus-color;
    }

    &.error {
        border-color: $color-error;
        background-color: $color-error-bg;
    }

    &.valid {
        border-color: $color-valid;
        background-color: $color-valid-bg;
    }
}

.combobox-wrapper {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-grey-1;
    overflow-y: auto;
    border-radius: 0;
    height: 100%;
    @media screen and (min-width: 768px) {
        position: absolute;
        top: $button-height + 0.4em;
        border-radius: 0.25rem;

        // scroll functionality
        overflow-y: hidden;
        height: auto;

        -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);

        // dropdown direction initial top
        &.dropup {
            top: -20.8em;

            -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
        }
    }

    // all about the input
    .search-input-container {
        z-index: 1;
        position: fixed;
        width: 100%;
        height: $input-height-mobile;
        background: $color-search-bar-mobile;

        & > * {
            line-height: 3rem;
            height: $input-height-mobile;
        }

        @media screen and (min-width: 768px) {
            position: relative;
            border-radius: 3px;
            height: $input-height-desktop;

            & > * {
                height: 3rem;
                line-height: $input-height-desktop;
            }
        }

        .ui-form-input {
            border-radius: 0;
            @media screen and (min-width: 768px) {
                border-radius: 0.25rem 0.25rem 0 0;
            }

            &:focus {
                box-shadow: none;
                border-width: 1.5px;
            }

            // style cancel search icon
            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        .input-addon {
            position: absolute;
            right: 0.75em;
            top: 0;
            font-size: 1.5em;
            padding: 0.4em 0.5em;
            width: 2.5rem;
            text-align: center;

            @media screen and (min-width: 768px) {
                right: 0.5em;
                font-size: 1em;
                padding: 0.1em 0.5em;
            }

            .remove-search {
                cursor: pointer;

                &:hover {
                    color: $focus-color;
                }
            }

            .loader {
                height: 1em;
            }

            &.pointer-events-none {
                pointer-events: none;
            }
        }
    }

    // all about the search results below the input
    .results-counter {
        height: $results-counter-height;
        padding: $option-padding;
    }
    .popup-label {
        height: $popup-label-height;
        line-height: math.div($popup-label-height, 3);
        padding: $option-padding;
        font-weight: bold;
        background: $color-grey-3;
        font-size: 0.8rem;
    }

    // all about the dropdown
    .listbox {
        width: 100%;
        overflow-x: hidden;

        margin-top: $input-height-mobile + $results-counter-height + $popup-label-height;
        height: calc(100% - $input-height-mobile - $results-counter-height - $popup-label-height);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        @media screen and (min-width: 768px) {
            margin-top: 0;
            border-radius: 0 0 0.25rem 0.25rem;

            // scroll functionality
            height: $listbox-height;
            overflow-y: auto;
        }

        & > .option {
            height: 3rem;
            line-height: 2rem;
            padding: $option-padding;
            color: $color-grey-7;
            display: -webkit-box;
            display: flex;
            align-items: center;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .option {
            background: $color-bg;
            cursor: pointer;

            .overflow-ellipsis {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            & + .option {
                border-top: 1px solid $color-grey-3;
            }

            &.disabled {
                color: $color-text-disabled;
            }

            &.selected {
                background: $selected-color;

                // selected indicator on mobile
                &:after {
                    content: '';
                    background-color: $focus-color;
                    height: 0.8em;
                    width: 0.8em;
                    border: 2px solid $selected-color;
                    pointer-events: none;

                    box-shadow: 0 0 0 2px transparent, 0 0 0 2px $focus-color;
                    border-radius: 50%;

                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }
                // position the selected indicator on mobile to the right
                > :last-child {
                    flex-grow: 2;

                    @media screen and (min-width: 768px) {
                        flex-grow: 0;
                    }
                }
            }

            &:not(.disabled):hover {
                background: $focus-color;
                color: $color-text-inverted;
                outline: none;
                & > * {
                    color: $color-text-inverted;
                }
            }

            &:not(.disabled):focus {
                background: $focus-color;
                color: $color-text-inverted;
                outline: none;

                & > * {
                    color: $color-text-inverted;
                }
            }
        }
    }
    .x-ripple {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        color: #666;
        display: block;
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/search.json"></i18n>
<i18n src="@dundle/locale/data/validation.json"></i18n>
<i18n />
